var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-information"},[_c('h2',{staticClass:"text-center"},[_vm._v("Let's get to know each other")]),_c('div',{staticClass:"form-information mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" First Name ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"first_name","id":"first_name","error-messages":_vm.invalid && !_vm.$v.info.firstName.required
              ? _vm.$t('First name is required!')
              : _vm.invalid && !_vm.$v.info.firstName.minLength
              ? _vm.$t('First name must have at least ' + _vm.$v.info.firstName.$params.minLength.min + ' letters.')
              : _vm.invalid && !_vm.$v.info.firstName.maxLength
              ? _vm.$t('First name must have at least ' + _vm.$v.info.firstName.$params.maxLength.max + ' letters.')
              : '',"placeholder":"Enter your first name"},model:{value:(_vm.$v.info.firstName.$model),callback:function ($$v) {_vm.$set(_vm.$v.info.firstName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.info.firstName.$model"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" Last Name ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"last_name","id":"last_name","error-messages":_vm.invalid && !_vm.$v.info.lastName.required
              ? _vm.$t('Last name is required!')
              : _vm.invalid && !_vm.$v.info.lastName.minLength
              ? _vm.$t('Last name must have at least ' + _vm.$v.info.lastName.$params.minLength.min + ' letters.')
              : _vm.invalid && !_vm.$v.info.lastName.maxLength
              ? _vm.$t('Last name must have at least ' + _vm.$v.info.lastName.$params.maxLength.max + ' letters.')
              : '',"placeholder":"Enter your last name"},model:{value:(_vm.$v.info.lastName.$model),callback:function ($$v) {_vm.$set(_vm.$v.info.lastName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.info.lastName.$model"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" Store country ")]),_c('v-combobox',{attrs:{"auto-select-first":"","items":_vm.countries,"error-messages":_vm.invalid && !_vm.$v.info.country.required ? _vm.$t('Store country is required!') : '',"item-text":"name"},model:{value:(_vm.info.country),callback:function ($$v) {_vm.$set(_vm.info, "country", $$v)},expression:"info.country"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" Your personal location ")]),_c('v-combobox',{attrs:{"auto-select-first":"","items":_vm.countries,"error-messages":_vm.invalid && !_vm.$v.info.location.required ? _vm.$t('Location is required!') : '',"item-text":"name"},on:{"change":_vm.selectedCountry},model:{value:(_vm.info.location),callback:function ($$v) {_vm.$set(_vm.info, "location", $$v)},expression:"info.location"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" How should we contact you? ")]),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0 m-0",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-combobox',{attrs:{"auto-select-first":"","items":_vm.countries,"error-messages":_vm.invalid && !_vm.$v.info.phoneNumberPrefix.required ? _vm.$t('Phone number prefix is required!') : '',"item-text":"calling_code"},model:{value:(_vm.info.phoneNumberPrefix),callback:function ($$v) {_vm.$set(_vm.info, "phoneNumberPrefix", $$v)},expression:"info.phoneNumberPrefix"}})],1),_c('v-col',{staticClass:"py-0 m-0",attrs:{"cols":"12","md":"8","sm":"8"}},[_c('v-text-field',{attrs:{"type":"number","placeholder":"98 4533888","error-messages":_vm.invalid && !_vm.$v.info.number.required
                  ? _vm.$t('Phone number is required!')
                  : _vm.invalid && !_vm.$v.info.number.minLength
                  ? _vm.$t('Phone number must have at least ' + _vm.$v.info.number.$params.minLength.min + ' letters.')
                  : _vm.invalid && !_vm.$v.info.number.maxLength
                  ? _vm.$t('Phone number must have at least ' + _vm.$v.info.number.$params.maxLength.max + ' letters.')
                  : ''},model:{value:(_vm.info.number),callback:function ($$v) {_vm.$set(_vm.info, "number", $$v)},expression:"info.number"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('span',{},[_vm._v(" Your personal social profile (recommend Telegram) ")]),_c('v-text-field',{attrs:{"placeholder":"@myusername"},model:{value:(_vm.info.profile),callback:function ($$v) {_vm.$set(_vm.info, "profile", $$v)},expression:"info.profile"}})],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"end","align-content":"end","justify":"end"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.next}},[_vm._v(" Next ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }