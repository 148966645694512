<template>
  <div>
    <ul class="d-flex nav-subscription">
      <li class="" v-for="(tab, index) in listTabs" :key="index">
        <button
          class="v-btn v-btn--contained theme--light v-size--default btn-subscription"
          :class="{ 'activeTab primary': isActive(tab) }"
          @click="setPaymentChoice(tab)"
        >
          {{ tab.title }} <span v-if="tab.subTitle">{{ tab.subTitle }}</span>
        </button>
      </li>
    </ul>
    <!-- <div class="d-flex" v-if="activeItem === 'yearly'">
      <div class="col-sm-4 col-12" v-for="(pricing, index) in pricingYear" :key="index">
        <div class="pricing-content">
          <div class="pricing-desc">
            <h2>{{ pricing.title }}</h2>
            <p>{{ pricing.subTitle }}</p>
            <div v-html="pricing.content"></div>
          </div>
          <div class="text-center pricing">
            <div class="amount">
              <span>{{ pricing.price }}</span>
              {{ pricing.subPrice }}
            </div>
            <button class="v-btn v-btn--contained theme--light v-size--default primary">
              {{ pricing.buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex" v-else>
      <div class="col-sm-4 col-12" v-for="(pricing, index) in pricingMonth" :key="index">
        <div class="pricing-content">
          <div class="pricing-desc">
            <h2>{{ pricing.title }}</h2>
            <p>{{ pricing.subTitle }}</p>
            <div v-html="pricing.content"></div>
          </div>
          <div class="text-center pricing">
            <div class="amount">
              <span>{{ pricing.price }}</span>
              {{ pricing.subPrice }}
            </div>
            <button class="v-btn v-btn--contained theme--light v-size--default primary">
              {{ pricing.buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="my-5 d-flex">
      <v-btn @click="$parent.$parent.$parent.e1 = 2" class="ml-1">Back</v-btn>
      <v-btn class="ml-auto" @click="setPaymentChoice(activeItem)" color="primary">Next</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      default(data) {},
    },
  },
  data() {
    return {
      listTabs: [
        {
          title: ' Latte Payment',
          subTitle: '',
          name: 'latte',
        },
        {
          title: `Seller's Payment`,
          subTitle: '',
          name: 'seller',
        },
      ],
      activeItem: {
        title: ' Latte Payment',
        subTitle: '',
        name: 'latte',
      },
      pricingYear: [
        {
          title: 'Basic',
          subTitle: 'Starter',
          content: `<ul><li>2 Project</li><li>400 Gb/s Storege</li><li>1 License</li><li>Tutorial Pack</li><li>Secure Data Storege</li></ul>`,
          price: '$79',
          subPrice: '/month',
          buttonText: 'Choose',
        },
        {
          title: 'Pro Business',
          subTitle: 'Better Results',
          content: `<ul><li>10 Project</li><li>20 Tb/s Storege</li><li>4 License</li><li>200 Presets</li><li>FCP and Premiere Plugin</li></ul>`,
          price: '$149',
          subPrice: '/month',
          buttonText: 'Choose',
        },
        {
          title: 'Platium',
          subTitle: 'Go all in',
          content: `<ul><li>Infinite Number of Project</li><li>200 Tb/s Storege</li><li>60 License</li><li>High Quality Stock Footage</li><li>On Site Support</li></ul>`,
          price: '$399',
          subPrice: '/month',
          buttonText: 'Choose',
        },
      ],
      pricingMonth: [
        {
          title: 'Basic',
          subTitle: 'Starter',
          content: `<ul><li>1 Project</li><li>100 Gb/s Storege</li><li>1 License</li><li>Tutorial Pack</li><li>Secure Data Storege</li></ul>`,
          price: '$39',
          subPrice: '/month',
          buttonText: 'Choose',
        },
        {
          title: 'Pro Business',
          subTitle: 'Better Results',
          content: `<ul><li>3 Project</li><li>300 Gb/s Storege</li><li>2 License</li><li>10 Presets</li><li>FCP and Premiere Plugin</li></ul>`,
          price: '$79',
          subPrice: '/month',
          buttonText: 'Choose',
        },
        {
          title: 'Platium',
          subTitle: 'Go all in',
          content: `<ul><li>10 Project</li><li>1 Tb/s Storege</li><li>5 License</li><li>High Quality Stock Footage</li><li>On Site Support</li></ul>`,
          price: '$149',
          subPrice: '/month',
          buttonText: 'Choose',
        },
      ],
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem.name === menuItem.name;
    },
    setActive(menuItem) {
      this.activeItem.name = menuItem.name;
    },
    setPaymentChoice(item) {
      this.activeItem.name = item.name;
      this.callback(item);
    },
  },
};
</script>

<style lang="scss">
ul.nav-subscription {
  align-items: center;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin: 30px 0;
  li {
    padding: 0 6px;
    span {
      text-transform: none;
      display: block;
      font-size: 14px;
    }
  }
  .btn-subscription {
    flex-direction: column;
  }
  .v-btn:not(.v-btn--round).btn-subscription {
    height: 55px;
    font-size: 18px;
  }
}

.pricing-desc {
  p {
    color: rgba(0, 0, 0, 0.38);
  }
  ul {
    list-style: none;
    li {
      position: relative;
      padding-bottom: 10px;
      &:before {
        position: absolute;
        content: '\F12C';
        font: normal normal normal 24px/1 'Material Design Icons';
        background-color: #a98a57;
        border-radius: 50%;
        font-size: 12px;
        padding: 3px;
        color: #fff;
        left: -26px;
      }
    }
  }
}

.pricing-content {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12);
  .pricing-desc {
    padding: 25px;
  }
}

.pricing {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 25px;
  .amount {
    color: rgba(0, 0, 0, 0.38);
    padding-bottom: 10px;
    span {
      color: #a98a57;
      font-size: 22px;
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) {
  .v-btn:not(.v-btn--round).btn-subscription {
    min-width: 220px;
  }
}
</style>
